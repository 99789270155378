import { apiErrorHandler } from "@/api/apiErrorHandler";
import api from "@/api";

const state = {
  activeFilterPerformers:
    localStorage.getItem("activeFilterPerformers") || "All",
  performersArr: [],
};
const mutations = {
  SET_ACTIVE_FILTER_PERFORMERS: (state, val) => {
    state.activeFilterPerformers = val;
    localStorage.setItem("activeFilterPerformers", val);
  },
  SET_PERFORMERS_ARR: (state, val) => {
    state.performersArr = val;
  },
};

const getters = {
  getActiveFilterPerformers: (state) => state.activeFilterPerformers,
  getPerformersArr: (state) => state.performersArr,
};

const actions = {
  setActiveFilterPerformers({ commit }, val) {
    try {
      commit("SET_ACTIVE_FILTER_PERFORMERS", val);
    } catch (error) {
      apiErrorHandler(error.message, error.response.status);
    }
  },
  async getPerformers({ commit }, query) {
    const performers = await api.request.getPerformersList(query);
    commit("SET_PERFORMERS_ARR", performers.data.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
