import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/toast";
import "@/assets/styles/style.scss";
import store from "@/store";
import i18n from "./i18n";
Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

Vue.mixin({
  data() {
    return {
      message: null,
      debounce: null,
    };
  },
  methods: {
    handleCustomChange(value, data) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.message = data;
        await this.preloadFunction(this.findFilter(this.message));
        data = null;
      }, 600);
    },
  },
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
  store,
}).$mount("#app");
