import { apiErrorHandler } from "@/api/apiErrorHandler";
import { apiSuccessHandler } from "@/api/apiSuccessHandler";
import api from "@/api";

const state = {
  activeClassificator: localStorage.getItem("activeClassificator"),
  activeList: [],
  namesGroups: [],
  listAll: [],
  countPage: null,
  isModalOpen: false,
  statePayload: {
    element_title: "",
    group_title: "",
    limit: 10,
    order_by: "",
    order_sort: "",
    page: 1,
  },
};
const getters = {
  getActiveClassificator: (state) => state.activeClassificator,
  getCountPage: (state) => state.countPage,
  getActivePage: (state) => state.statePayload.page,
  getActiveGroup: (state) => state.statePayload.group_title,
  getNamesGroups: (state) => state.namesGroups,
  getActiveList: (state) => state.activeList,
  getListAll: (state) => state.listAll,
  getIsModalOpen: (state) => state.isModalOpen,
};
const mutations = {
  SET_ACTIVE_CLASSIFICATOR(state, name) {
    state.activeClassificator = name;
    localStorage.setItem("activeClassificator", name);
  },
  SET_GROUP(state, name) {
    state.statePayload.group_title = name;
  },
  SET_LIST_ALL(state, arr) {
    state.listAll = arr;
  },
  SET_LIST(state, arr) {
    state.activeList = arr;
  },
  SET_GROUPS(state, arr) {
    state.namesGroups = Object.assign([...arr, { id: 0, title: "All" }]);
  },
  TOGGLE_MODAL: (state) => {
    state.isModalOpen = !state.isModalOpen;
  },
  SET_COUNT_PAGE: (state, count) => (state.countPage = count),
  SET_ACTIVE_PAGE: (state, num) => {
    state.statePayload.page = num;
  },
  SET_ORDER_SORT: (state, sort) => (state.statePayload.order_sort = sort),
  SET_ORDER_BY: (state, order) => (state.statePayload.order_by = order),
};
const actions = {
  //...........refreshList
  async refreshList({ commit, state }) {
    try {
      const refresh = await api.request.getList(state.activeClassificator, {
        ...state.statePayload,
      });
      commit("SET_LIST", refresh.data.data);
    } catch (e) {
      console.log(e, "refreshList");
    }
  },
  //...........refreshGroup
  async refreshGroup({ commit, state }) {
    try {
      const groups = await api.request.getGroups(state.activeClassificator, {
        ...state.statePayload,
      });
      commit("SET_GROUPS", groups.data.data);
    } catch (e) {
      console.log(e, "refreshGroup");
    }
  },
  //C...........add group
  async addNewGroup({ commit, state, dispatch }, payload) {
    const res = await api.request.addNewGroup(state.activeClassificator, {
      title: payload.createTitle,
    });
    apiSuccessHandler(res.status, "created!");
    await dispatch("refreshList");
    await dispatch("refreshGroup");
    commit("TOGGLE_MODAL", false);
  },
  //C...........add filter
  async addNewFilter({ commit, state, dispatch }, payload) {
    console.log(payload);
    const newPayload = {
      title: payload["write a filter"],
      group_id: state.namesGroups.find(
        (el) => el.title === payload["choose a group"]
      ).id,
    };
    const res = await api.request.addNewFilter(
      state.activeClassificator,
      newPayload
    );
    apiSuccessHandler(res.status, "created!");
    await dispatch("refreshList");
    commit("TOGGLE_MODAL", false);
  },
  //R...........set list
  async setActiveClassificator({ commit, state }, name) {
    try {
      commit("common/SET_IS_LOADING", true, { root: true });
      commit("SET_ACTIVE_PAGE", 1);
      commit("SET_ORDER_SORT", "");
      commit("SET_ORDER_BY", "");
      commit("SET_ORDER_BY", "");
      commit("SET_GROUP", "");
      const list = await api.request.getList(name, {
        ...state.statePayload,
      });
      commit("SET_LIST", list.data.data);
      const listAll = await api.request.getList(name, {
        ...state.statePayload,
        limit: -1,
      });
      commit("SET_LIST_ALL", listAll.data.data);
      commit(
        "SET_COUNT_PAGE",
        Math.ceil(
          listAll.data.pagination.total_count / list.data.pagination.limit
        )
      );
      const groups = await api.request.getGroups(name, {
        ...state.statePayload,
      });
      commit("SET_GROUPS", groups.data.data);
    } catch (error) {
      apiErrorHandler(error.message, error.response.status);
      // заглушка для Location and Skills
      commit("SET_LIST", []);
    } finally {
      commit("SET_GROUP", "");
      commit("SET_ACTIVE_CLASSIFICATOR", name);
      commit("common/SET_IS_LOADING", false, { root: true });
    }
  },
  //R...........setGroup
  async setActiveGroup({ commit, state }, name) {
    commit("SET_ACTIVE_PAGE", 1);
    commit("SET_GROUP", name === "All" ? "" : name);
    const res = await api.request.getList(state.activeClassificator, {
      ...state.statePayload,
    });
    commit("SET_LIST", res.data.data);
    commit(
      "SET_COUNT_PAGE",
      Math.ceil(res.data.pagination.total_count / res.data.pagination.limit)
    );
  },
  //R..........findFilter
  async findFilter({ state, commit }, name) {
    const newPayload = {
      ...state.statePayload,
      element_title: name,
    };
    commit("SET_ACTIVE_PAGE", 1);
    const res = await api.request.getList(
      state.activeClassificator,
      newPayload
    );
    commit("SET_LIST", res.data.data);
    commit(
      "SET_COUNT_PAGE",
      Math.ceil(res.data.pagination.total_count / res.data.pagination.limit)
    );
  },
  //U...........sortList
  async sortList({ commit, state }, sort) {
    commit("SET_ORDER_SORT", sort.order_sort);
    commit("SET_ORDER_BY", sort.order_by);
    commit("SET_ACTIVE_PAGE", 1);
    const res = await api.request.getList(state.activeClassificator, {
      ...state.statePayload,
      ...sort,
    });
    commit("SET_LIST", res.data.data);
  },
  //U...........editFilter
  async editFilter({ state, commit, dispatch }, payload) {
    commit("common/SET_IS_LOADING", true, { root: true });
    const res = await api.request.editFilter(
      state.activeClassificator,
      payload
    );
    apiSuccessHandler(res.status, "updated!");
    await dispatch("refreshList");
    commit("TOGGLE_MODAL", false);
  },
  //U...........editGroup
  async editGroup({ state, commit, dispatch }, payload) {
    const updGroup = await api.request.editGroup(
      state.activeClassificator,
      payload
    );
    apiSuccessHandler(updGroup.status, "updated!");
    await dispatch("refreshGroup");
    commit("TOGGLE_MODAL", false);
  },
  //U...........changePage
  async changePage({ commit, state }, page) {
    commit("SET_ACTIVE_PAGE", page);
    const newPortion = await api.request.getList(state.activeClassificator, {
      ...state.statePayload,
      page,
    });
    commit("SET_LIST", newPortion.data.data);
  },
  //D...........deleteFilter
  async deleteFilter({ state, commit, dispatch }, id) {
    const res = await api.request.deleteFilter(state.activeClassificator, id);
    apiSuccessHandler(res.status, "deleted!");
    await dispatch("refreshList");
    commit("TOGGLE_MODAL", false);
  },
  //D...........deleteGroup
  async deleteGroup({ state, commit, dispatch }, id) {
    const res = await api.request.deleteGroup(state.activeClassificator, id);
    apiSuccessHandler(res.status, "deleted!");
    await dispatch("refreshGroup");
    commit("SET_GROUP", "");
    commit("TOGGLE_MODAL", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
