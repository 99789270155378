export default (instance) => {
  return {
    setLogin: (payload) => instance.post("login", payload),
    isAuth: () =>
      instance.post(
        "isauth",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ),
    setLogout: () =>
      instance.post(
        "logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ),
    getPerformersList: (query) =>
      instance.get(`experts?search_query=${query || ""}`),
    getGroups: (name, payload) =>
      instance.post(`get_group_${name.toLowerCase()}`, payload),
    getList: (name, payload) =>
      instance.post(`get_${name.toLowerCase()}`, payload),
    addNewFilter: (name, payload) =>
      instance.post(`set_${name.toLowerCase()}`, payload),
    editFilter: (name, payload) =>
      instance.post(`upd_${name.toLowerCase()}`, payload),
    deleteFilter: (name, id) =>
      instance.post(`delete_${name.toLowerCase()}?id=${id}`),
    addNewGroup: (name, payload) =>
      instance.post(`set_group_${name.toLowerCase()}`, payload),
    editGroup: (name, payload) =>
      instance.post(`upd_group_${name.toLowerCase()}`, payload),
    deleteGroup: (name, id) =>
      instance.post(`delete_group_${name.toLowerCase()}?id=${id}`),
  };
};
