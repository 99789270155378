import Vue from "vue";

export const apiErrorHandler = (error, numberError) => {
  switch (numberError) {
    case 401: {
      Vue.$toast.open({
        message: `Incorrect Email or Password`,
        type: "error",
      });
      break;
    }
    case 400: {
      Vue.$toast.open({
        message: `Error request`,
        type: "error",
      });
      break;
    }
    case 404: {
      Vue.$toast.open({
        message: `Page not found`,
        type: "error",
      });
      break;
    }
    case 500: {
      Vue.$toast.open({
        message: `Server error`,
        type: "error",
      });
      break;
    }
    default: {
      Vue.$toast.open({
        message: `${error} `,
        type: "error",
      });
    }
  }
};
