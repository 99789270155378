import axios from "axios";

export default () => {
  if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = "https://dev.admin.dimpulse.biz/api/";
  }
  if (process.env.NODE_ENV === "production") {
    axios.defaults.baseURL = "https://" + window.location.hostname + "/api";
  }
  if (process.env.NODE_ENV === "local") {
    axios.defaults.baseURL = "https://" + window.location.hostname + "/api";
  }
};
