import Vue from "vue";
import Router from "vue-router";
import api from "@/api";
import { apiErrorHandler } from "@/api/apiErrorHandler";

const activeClassificator = localStorage.getItem("activeClassificator");
Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Main",
      beforeEnter: async (to, from, next) => {
        try {
          const result = await api.request.isAuth();
          if (result.status === 200) {
            return next();
          }
        } catch (error) {
          apiErrorHandler("You are not authorized");
          router.push({ name: "Auth" });
        }
      },
      component: () => import("@/layouts/main-layout.vue"),
      redirect: "/requests",
      children: [
        {
          path: "/requests",
          name: "Requests",
          component: () => import("@/views/requests-view.vue"),
        },
        {
          path: "/classificator",
          name: "Classificator",
          component: () => import("@/views/classificator-view.vue"),
          redirect:
            activeClassificator &&
            `classificator/${activeClassificator.toLowerCase()}`,
          children: [
            {
              path: "expertise",
              name: "Classificator/expertise",
              component: () => import("@/views/classificator-filter-view.vue"),
            },
            // {
            //   path: "profile",
            //   name: "Classificator/Profile",
            //   component: () => import("@/views/classificator-filter-view.vue"),
            // // },
            {
              path: "industry",
              name: "Classificator/industry",
              component: () => import("@/views/classificator-filter-view.vue"),
            },
            // {
            //   path: "location",
            //   name: "Classificator/Location",
            //   component: () => import("@/views/classificator-filter-view.vue"),
            // },
            // {
            //   path: "skills",
            //   name: "Classificator/Skills",
            //   component: () => import("@/views/classificator-filter-view.vue"),
            // },
          ],
        },
        {
          path: "/performers",
          name: "Performers",
          component: () => import("@/views/performers-view.vue"),
        },
        {
          path: "/settings",
          name: "Settings",
          component: () => import("@/views/settings-view.vue"),
        },
        {
          path: "/projects",
          name: "Projects",
          component: () => import("@/views/projects-view.vue"),
        },
      ],
    },
    {
      path: "/auth",
      name: "Auth",
      component: () => import("@/layouts/auth-layout.vue"),
      redirect: "auth/login",
      children: [
        {
          path: "login",
          name: "LoginView",
          component: () => import("@/views/login-view.vue"),
        },
        {
          path: "register",
          name: "RegisterView",
          component: () => import("@/views/register-view.vue"),
        },
        {
          path: "forgot",
          name: "ForgotView",
          component: () => import("@/views/forgot-view.vue"),
        },
      ],
    },
    {
      name: "Error",
      path: "/*",
      component: () => import("@/layouts/error-layout.vue"),
    },
  ],
});
export default router;
