import axios from "axios";
import config from "@/helpers/config";

config();

const instance = axios.create({
  baseURL: "/api",
  headers: { "Content-Type": "application/json" },
});

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${
      localStorage.getItem("access_token") || ""
    }`;
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
