import router from "@/router";
import { apiErrorHandler } from "@/api/apiErrorHandler";
import api from "@/api";

const ACCESS_TOKEN = "access_token";

const state = {
  accessToken: localStorage.getItem(ACCESS_TOKEN),
  refresh_token: "",
};
const mutations = {
  SET_TOKEN: (state, token) => {
    state.accessToken = token;
    localStorage.setItem(ACCESS_TOKEN, token);
  },
};
const actions = {
  async setLogin({ commit }, payload) {
    try {
      const res = await api.request.setLogin(payload);
      if (res) {
        commit("SET_TOKEN", res.data[ACCESS_TOKEN]);
        await router.push({ name: "Main" });
      }
    } catch (error) {
      apiErrorHandler(error.message, error.response.status);
    }
  },

  async setLogout({ state }) {
    try {
      await api.request.setLogout(state.accessToken);
      localStorage.clear();
      await router.push({ name: "Auth" });
    } catch (error) {
      apiErrorHandler(error.message, error.response.status);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
