import { apiErrorHandler } from "@/api/apiErrorHandler";

const state = {
  isLoading: false,
};
const getters = {
  getIsLoading: (state) => state.isLoading,
};
const mutations = {
  SET_IS_LOADING: (state, toggle) => (state.isLoading = toggle),
};
const actions = {
  async preloadFunction({ commit }, func) {
    try {
      commit("SET_IS_LOADING", true);
      await func;
    } catch (error) {
      apiErrorHandler(error.message, error.response.status);
    } finally {
      commit("SET_IS_LOADING", false);
    }
  },
};
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
