import Vue from "vue";

export const apiSuccessHandler = (status, message) => {
  switch (status) {
    case 200: {
      Vue.$toast.open({
        message: `Successfully \n ${message} `,
        type: "success",
      });
    }
  }
};
