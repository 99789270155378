const state = {
  asidePanelItem: [
    {
      title: "requests",
      location: "/requests",
    },
    {
      title: "classificator",
      location: "/classificator",
      listVariants: ["expertise", "industry"],
      //  "Profile", "Skills", "Location",
    },
    {
      title: "performers",
      location: "/performers",
    },
    {
      title: "projects",
      location: "_/projects",
    },
  ],
  asideBottomControl: [
    {
      title: "settings",
      location: "/settings",
    },
    {
      title: "logout",
      location: "/auth/logout",
    },
  ],
};
const getters = {
  getAsidePanelItem: (state) => state.asidePanelItem,
  getAsideBottomControl: (state) => state.asideBottomControl,
};
export default {
  namespaced: true,
  state,
  getters,
};
