import Vue from "vue";
import Vuex from "vuex";
import aside from "@/store/aside/aside";
import auth from "@/store/auth/auth";
import classificator from "@/store/classificator/classificator";
import common from "@/store/common/common";
import performers from "@/store/performers/performers";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { aside, auth, classificator, common, performers },
});
